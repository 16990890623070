// import "@bam-spark/nav-toggle";
require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');

import Headroom from "headroom.js/dist/headroom.min.js";

const header = document.querySelector(".c-header__wrapper");
var options = { offset: 39 }
const headroom = new Headroom(header, options);
headroom.init();

var scrollHead = document.querySelector("#scrollHead");
if ( scrollHead ) {
    var options = { offset: 39 }

    const scrollHeadroom = new Headroom(scrollHead, options);
    scrollHeadroom.init();
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});


let navToggle = document.querySelector('.js-nav-toggle');
let menuWrapper = document.querySelector('.c-nav--primary__wrapper');

navToggle.addEventListener('click', function() {

    if (navToggle.classList.contains('utl-is-active')) {
        navToggle.classList.remove('utl-is-active');
        menuWrapper.classList.remove('utl-is-active');
        document.body.classList.remove("utl-menu-open");
        this.setAttribute('aria-expanded', 'false');

    } else {
        navToggle.classList.add('utl-is-active');
        menuWrapper.classList.add('utl-is-active');
        document.body.classList.add("utl-menu-open");
        this.setAttribute('aria-expanded', 'true');
    }
});

const toggles = document.querySelectorAll('.js-toggle');

for (let a of toggles) {
    a.addEventListener('click', function(event) {

        if (a.classList.contains('utl-is-expanded')) {
            a.classList.remove('utl-is-expanded');
            a.setAttribute('aria-expanded', 'false');
            event.preventDefault();

        } else {
            a.classList.add('utl-is-expanded');
            a.setAttribute('aria-expanded', 'true');
            event.preventDefault();
        }
    });
}

var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
    new Waypoint({
        element: continuousElements[i],
        handler: function() {
            this.element.classList.add("js-animate--start")
        },
        offset: '97%'
    })
}

// tour head
var scrollHead = document.querySelector('#scrollHead');
var mainHeader = document.querySelector('#header-wrapper');

if (scrollHead) {
    window.onscroll = function() {
        //bottom of scrollHead (relative)
        if (window.scrollY > (scrollHead.offsetTop + scrollHead.offsetHeight)) {
             scrollHead.classList.add('utl-is-fixed');
         }

        //top of page
        if(window.scrollY < (scrollHead.offsetTop + scrollHead.offsetHeight - mainHeader.offsetHeight)) {
            scrollHead.classList.remove('utl-is-fixed');
        }
    }
}

//isotope for testimonial page
var elem = document.querySelector('.c-testimonial__wrapper--testimonial-page');

if ( elem ) {
    var iso = new Isotope( elem, {
      // options
      itemSelector: '.c-testimonial',
      layoutMode: 'masonry'
    });
    // run function again on resize
    window.addEventListener("resize", function(){
        var iso = new Isotope( elem, {
          // options
          itemSelector: '.c-testimonial',
          layoutMode: 'masonry'
        });
    });
}


// popup Modal
var modal = document.getElementById("popUp");
var btn = document.getElementById("bookNow");
var span = document.getElementsByClassName("close")[0];

// When the user clicks on the button, open the modal
if ( modal ) {
    btn.onclick = function() {
      modal.style.display = "block";
    }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }

    var form = modal.querySelector('form');

    if ( form ) {
        let submit = form.querySelector('button[type=submit]')
        submit.addEventListener('click', function() {
            modal.scrollTo({
                 top: 0,
                 behavior: "smooth"
            });
        });
    }
}


// Check Jquery is working
// window.onload = function() {
//     if (window.jQuery) {
//         alert("Jquery Working");
//     } else {
//         alert("Jquery Doesn't Work");
//     }
// }

var submitbtn = document.getElementsByClassName("a-button--submit")[0];

if ( submitbtn ) {
    submitbtn.onclick = function() {
    document.querySelectorAll('.ff-form-errors').forEach(e => e.remove());
    }
}
